import '@material/web/icon/icon.js'
import '@material/web/button/elevated-button.js'

import '@operato/lottie-player'

import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { i18next, localize } from '@operato/i18n'
import { ScrollbarStyles } from '@operato/styles'
import { isSafari } from '@operato/utils'

import { AUTH_STYLE_SIGN } from '../../auth-style-sign'

@customElement('auth-checkin')
export class AuthCheckIn extends localize(i18next)(LitElement) {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: var(--md-sys-color-primary);
        color: var(--md-sys-color-on-primary);
        height: 100vh;
        height: 100dvh;
      }

      .header {
        background-color: var(--md-sys-color-primary);
        color: var(--md-sys-color-on-primary);
        height: var(--checkin-header-height);
      }

      .content {
        flex: 1;
        overflow: auto;
      }

      .domains {
        margin: var(--spacing-large) 0;
        padding: 0;
        background-color: var(--md-sys-color-surface);
        color: var(--md-sys-color-on-surface);
        border-radius: var(--border-radius);
        border: var(--border-dim-color);
        list-style: none;
      }

      li {
        border-bottom: var(--border-dim-color);
        padding: var(--spacing-medium) var(--spacing-large);
        font-size: 18px;
        text-align: left;

        cursor: pointer;
      }

      li:hover {
        background-color: var(--md-sys-color-primary-container);
        color: var(--md-sys-color-on-primary-container);
      }

      li span {
        display: block;
        font: normal var(--fontsize-default) var(--theme-font);
      }

      li md-icon {
        float: right;
        margin: 10px 0 0 0;
        opacity: 0.5;
      }

      .button-container {
        text-align: center;
      }

      .button-container md-elevated-button {
        margin-left: var(--spacing-small);
      }

      @media (max-width: 450px) {
        .button-container md-elevated-button {
          width: 100%;
          margin: var(--spacing-medium) 0 0 0;
        }
      }
    `,
    AUTH_STYLE_SIGN
  ]

  @property({ type: Object }) data: any
  @property({ type: Array }) domains: any[] = []
  @property({ type: Array }) domainTypes?: string[]
  @property({ type: Object }) user: any

  private _applicationMeta?: { icon?: string; title?: string; description?: string }
  private redirectTo?: string

  render() {
    var { icon, title, description } = this.applicationMeta

    return html`
      <div class="content md-typescale-display-medium">
        <div class="wrap">
          <div class="auth-brand">
            <img src=${icon || ''} />
            <strong class="name">${title}</strong>
            <span class="welcome-msg">${description}</span>
          </div>

          <h3>${i18next.t('label.select_domain')}</h3>

          ${this.domains?.length
            ? html`
                <ul class="domains">
                  ${this.domains.map(
                    domain => html`
                      <li
                        @click=${() =>
                          (location.href = `/auth/checkin/${domain.subdomain}?redirect_to=${encodeURIComponent(this.redirectTo || '/')}`)}
                      >
                        <md-icon>keyboard_arrow_right</md-icon>
                        <strong>${domain.name}</strong>
                        <span>${domain.description}&nbsp;</span>
                      </li>
                    `
                  )}
                </ul>
              `
            : html` <h3>${i18next.t('text.no domain available')}</h3> `}

          <div class="button-container">
            <md-elevated-button @click=${() => (location.pathname = '/auth/signout')}
              >${String(i18next.t('button.logout'))}</md-elevated-button
            >
          </div>

          ${isSafari()
            ? html``
            : html`
                <div class="lottie-container">
                  <lottie-player autoplay loop src="../../assets/images/background-animation.json"></lottie-player>
                </div>
              `}
        </div>
      </div>
    `
  }

  updated(changed) {
    if (changed.has('data')) {
      this.domains = this.data.domains
      this.user = this.data.user
      this.domainTypes = this.data.domainTypes
      this.redirectTo = this.data.redirectTo

      this.requestUpdate()
    }
  }

  navigateToUrl(url) {
    location.pathname = url
  }

  get applicationMeta() {
    if (!this._applicationMeta) {
      var iconLink: HTMLLinkElement | null = document.querySelector('link[rel="application-icon"]')
      var titleMeta: HTMLMetaElement | null = document.querySelector('meta[name="application-name"]')
      var descriptionMeta: HTMLMetaElement | null = document.querySelector('meta[name="application-description"]')

      this._applicationMeta = {
        icon: iconLink?.href,
        title: titleMeta ? titleMeta.content : 'Things Factory',
        description: descriptionMeta ? descriptionMeta.content : 'Reimagining Software'
      }
    }

    return this._applicationMeta
  }
}
